<section class="logo-container">
  <img class="puppet" src="/assets/images/Ranked-Puppet_Bar.svg" alt="" />
  <h2 class="app-name">{{ 'common.name' | translate }}</h2>
  <p class="app-slogan">{{ 'common.slogan' | translate }}</p>
</section>

<h1 class="headline">{{ 'room-rankings.headline' | translate }}</h1>

<ranked-ranking-list
  *ngIf="winRateRanking$ | async as winRateRanking"
  class="ranking"
  [ranking]="winRateRanking"
  unit="%"
  [decimalCount]="1"
></ranked-ranking-list>
