import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ENVIRONMENT_TOKEN } from '@ranked/settings';
import { SharedUiModule } from '@ranked/shared-ui';
import { StatisticsBasicModule } from '@ranked/statistics';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { JoinRoomComponent } from './components/join-room/join-room.component';
import { RoomRankingsComponent } from './components/room-rankings/room-rankings.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, RoomRankingsComponent, HomePageComponent, JoinRoomComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    FormsModule,
    SharedUiModule,
    StatisticsBasicModule,
  ],
  providers: [{ provide: ENVIRONMENT_TOKEN, useValue: environment }],
  bootstrap: [AppComponent],
})
export class AppModule {}
