import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './components/home-page/home-page.component';
import { JoinRoomComponent } from './components/join-room/join-room.component';
import { RoomRankingsComponent } from './components/room-rankings/room-rankings.component';

const routes: Routes = [
  { path: 'ranking', component: JoinRoomComponent },
  { path: 'ranking/:roomId', component: RoomRankingsComponent },
  { path: '', pathMatch: 'full', component: HomePageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
