import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { AllowedContextTypes, AllowedTimeFrames, Ranking, StatisticsClientService } from '@ranked/statistics';
import { RoomClientService } from '../../services/room-client.service';

@Component({
  selector: 'ranked-room-rankings',
  templateUrl: './room-rankings.component.html',
  styleUrls: ['./room-rankings.component.scss'],
})
export class RoomRankingsComponent {
  private readonly AUTO_REFRESH_DELAY = 10000;

  public winRateRanking$: Observable<Ranking>;

  constructor(
    activatedRoute: ActivatedRoute,
    private roomClientService: RoomClientService,
    private statisticsClientService: StatisticsClientService,
  ) {
    this.winRateRanking$ = activatedRoute.paramMap.pipe(
      map((params) => params.get('roomId')),
      filter((roomId) => roomId !== undefined && roomId.length > 0),
      mergeMap((roomId) => {
        return this.loadRanking(roomId, 'MatchesWonLostRatio', AllowedTimeFrames.ALL_TIME).pipe(
          map((ranking) =>
            ranking.map((rankingValue) => ({
              ...rankingValue,
              value: rankingValue.value * 100,
            })),
          ),
        );
      }),
    );
  }

  private loadRanking(roomId: string, rankingName: string, timeFrame: AllowedTimeFrames): Observable<Ranking> {
    return this.roomClientService
      .getRoomParticipants(roomId)
      .pipe(
        mergeMap((participants) =>
          timer(0, this.AUTO_REFRESH_DELAY).pipe(
            mergeMap(() =>
              this.statisticsClientService
                .getRanking(rankingName, { contextType: AllowedContextTypes.ROOM, timeFrame }, participants, undefined, roomId)
                .pipe(catchError(() => of([]))),
            ),
          ),
        ),
      );
    // return of([
    //   {
    //     participant: {
    //       id: '5481fb11-7255-4241-a40a-b68822d2a144',
    //       name: 'Velma Marquardt DVM',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_9.png',
    //     },
    //     value: 0.993,
    //   },
    //   {
    //     participant: {
    //       id: '92adcbc0-2dda-4bc4-bda1-ef7611825fa1',
    //       name: 'Mitchell Breitenberg',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_35.png',
    //     },
    //     value: 0.965,
    //   },
    //   {
    //     participant: {
    //       id: '862fc19a-659c-4723-8e81-1944ac0e8c62',
    //       name: 'Dr. Vernon Spinka',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_33.png',
    //     },
    //     value: 0.961,
    //   },
    //   {
    //     participant: {
    //       id: '00ef763f-05c7-451f-99f4-b7676b73774a',
    //       name: 'Damon Leuschke',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_28.png',
    //     },
    //     value: 0.959,
    //   },
    //   {
    //     participant: {
    //       id: '9ac749f1-d536-425c-a6aa-29e49b23e2ba',
    //       name: 'Miss Ellis Goldner',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_22.png',
    //     },
    //     value: 0.9,
    //   },
    //   {
    //     participant: {
    //       id: '101b13b7-a335-4703-acc3-2939e5f90193',
    //       name: 'Timmy Lueilwitz',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_39.png',
    //     },
    //     value: 0.886,
    //   },
    //   {
    //     participant: {
    //       id: '68102739-0705-443a-96ea-ae0561c1f25a',
    //       name: 'Donna Stracke',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_37.png',
    //     },
    //     value: 0.88,
    //   },
    //   {
    //     participant: {
    //       id: 'f207a637-e921-404e-9285-b6bc616597d2',
    //       name: 'Vera Ruecker',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_25.png',
    //     },
    //     value: 0.871,
    //   },
    //   {
    //     participant: {
    //       id: '7917e0e9-9ee3-4edd-bf8a-4b88fa147134',
    //       name: 'Adrian Powlowski',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_41.png',
    //     },
    //     value: 0.861,
    //   },
    //   {
    //     participant: {
    //       id: 'ada5f28d-ee4c-4d74-8f05-e62e6dd310e1',
    //       name: 'Roosevelt Hamill DDS',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_3.png',
    //     },
    //     value: 0.833,
    //   },
    //   {
    //     participant: {
    //       id: 'd6f2c21f-75d9-40d8-bc04-f837df2f3c80',
    //       name: 'Christie Doyle V',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_21.png',
    //     },
    //     value: 0.827,
    //   },
    //   {
    //     participant: {
    //       id: 'ea3251b7-010c-40d4-9843-e35296ca5d99',
    //       name: 'Philip Fadel',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_6.png',
    //     },
    //     value: 0.825,
    //   },
    //   {
    //     participant: {
    //       id: '3ccfddc4-5690-4471-a9a6-3377320c1195',
    //       name: 'Ramiro Rohan PhD',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_24.png',
    //     },
    //     value: 0.771,
    //   },
    //   {
    //     participant: {
    //       id: '910d5005-9701-4008-943a-9434c6f64c04',
    //       name: 'Frankie Leffler',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_14.png',
    //     },
    //     value: 0.745,
    //   },
    //   {
    //     participant: {
    //       id: '8a93b06c-bac2-47f9-aa34-3e22072e73bd',
    //       name: 'Kent Hilpert III',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_38.png',
    //     },
    //     value: 0.723,
    //   },
    //   {
    //     participant: {
    //       id: '7b9a5a61-033c-4bd2-942e-147d6a0150eb',
    //       name: 'Flora Goodwin',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_3.png',
    //     },
    //     value: 0.701,
    //   },
    //   {
    //     participant: {
    //       id: '24ac5390-f116-4fda-98c7-af83f201bc82',
    //       name: 'Arthur Wunsch',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_42.png',
    //     },
    //     value: 0.663,
    //   },
    //   {
    //     participant: {
    //       id: '276f565f-8ff4-481e-abd4-0ba9ff2e7773',
    //       name: 'Elaine Ratke',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_0.png',
    //     },
    //     value: 0.654,
    //   },
    //   {
    //     participant: {
    //       id: 'b5c8d506-75ff-4d9c-a9dd-e05e877d73bb',
    //       name: 'Joyce Schuppe III',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_1.png',
    //     },
    //     value: 0.651,
    //   },
    //   {
    //     participant: {
    //       id: '226750f6-d10a-48b7-ad5e-60d88bc967b2',
    //       name: 'Naomi Gulgowski',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_8.png',
    //     },
    //     value: 0.643,
    //   },
    //   {
    //     participant: {
    //       id: '6cba68c2-22cd-474d-a676-cf3d32c297d5',
    //       name: 'Diana Hoppe',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_12.png',
    //     },
    //     value: 0.631,
    //   },
    //   {
    //     participant: {
    //       id: '7e51d306-2b07-41be-9f09-07ebe8b12b7c',
    //       name: 'Sean Fahey',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_37.png',
    //     },
    //     value: 0.63,
    //   },
    //   {
    //     participant: {
    //       id: '197dfa07-8ee5-44fb-8656-1f21fe9f78d6',
    //       name: 'Maria Terry',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_35.png',
    //     },
    //     value: 0.603,
    //   },
    //   {
    //     participant: {
    //       id: 'ebd7a75d-82b9-44c7-8d66-d14895117ff1',
    //       name: 'Enrique Jacobi',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_42.png',
    //     },
    //     value: 0.565,
    //   },
    //   {
    //     participant: {
    //       id: '67455c7d-87eb-4fe7-98a0-0efd5798f439',
    //       name: 'Traci Effertz',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_21.png',
    //     },
    //     value: 0.552,
    //   },
    //   {
    //     participant: {
    //       id: '07a8a393-a109-42b5-bd6a-d4e0516a39f3',
    //       name: 'Stacey Kuhn DVM',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_12.png',
    //     },
    //     value: 0.544,
    //   },
    //   {
    //     participant: {
    //       id: '33b7ecb5-377a-41e0-83f2-cbb2e516e5dd',
    //       name: 'Opal Towne Sr.',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_42.png',
    //     },
    //     value: 0.54,
    //   },
    //   {
    //     participant: {
    //       id: '3135b369-c5fc-4c76-ac50-46d448f1e0c3',
    //       name: 'Stacy Osinski',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_31.png',
    //     },
    //     value: 0.522,
    //   },
    //   {
    //     participant: {
    //       id: '2634092a-c680-481c-8557-f24474aee17c',
    //       name: 'Willis Huels',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_19.png',
    //     },
    //     value: 0.512,
    //   },
    //   {
    //     participant: {
    //       id: '5c67a079-a241-46dc-8ac9-95bde10eea0d',
    //       name: 'Nicole Swaniawski',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_34.png',
    //     },
    //     value: 0.51,
    //   },
    //   {
    //     participant: {
    //       id: '0290bdd9-0ada-49f1-ab76-990c0ea97f1f',
    //       name: 'Gerard Luettgen',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_7.png',
    //     },
    //     value: 0.488,
    //   },
    //   {
    //     participant: {
    //       id: '4409a821-798b-44a1-b7a9-414254e334bf',
    //       name: 'Katrina Buckridge',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_19.png',
    //     },
    //     value: 0.471,
    //   },
    //   {
    //     participant: {
    //       id: 'ee201702-1762-4b2e-9b74-090a15a3f633',
    //       name: 'Eduardo Lueilwitz',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_1.png',
    //     },
    //     value: 0.443,
    //   },
    //   {
    //     participant: {
    //       id: 'a8443a5e-9176-489b-8aaf-2a626345c574',
    //       name: 'Earl Hammes',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_37.png',
    //     },
    //     value: 0.375,
    //   },
    //   {
    //     participant: {
    //       id: '7f317368-796a-475d-9a8e-57281c3d4081',
    //       name: 'Kerry Reilly',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_20.png',
    //     },
    //     value: 0.368,
    //   },
    //   {
    //     participant: {
    //       id: '3e0100ed-f53d-4e50-a7c4-64d0e366f7ca',
    //       name: 'Irvin Haag',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_8.png',
    //     },
    //     value: 0.348,
    //   },
    //   {
    //     participant: {
    //       id: '0950574d-c88b-4973-8f15-e98457922038',
    //       name: 'Terrell Rippin',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_33.png',
    //     },
    //     value: 0.335,
    //   },
    //   {
    //     participant: {
    //       id: 'ee508bf2-b925-4b95-bf93-937302e4aeec',
    //       name: 'Mr. Brandy Kozey',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_16.png',
    //     },
    //     value: 0.335,
    //   },
    //   {
    //     participant: {
    //       id: '7f1cf590-da19-4b56-b7cb-273b16e45fab',
    //       name: 'Colleen Prohaska',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_27.png',
    //     },
    //     value: 0.25,
    //   },
    //   {
    //     participant: {
    //       id: 'edee2536-5a5c-44cb-9b73-3843e0f9a43f',
    //       name: 'Delores Bashirian',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_21.png',
    //     },
    //     value: 0.185,
    //   },
    //   {
    //     participant: {
    //       id: '887b276e-7d37-4060-8922-59e75563977f',
    //       name: 'Sheryl Deckow',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_33.png',
    //     },
    //     value: 0.124,
    //   },
    //   {
    //     participant: {
    //       id: 'e425c660-2a72-4934-bae5-931beb823152',
    //       name: 'Dr. Violet Prohaska',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_23.png',
    //     },
    //     value: 0.111,
    //   },
    //   {
    //     participant: {
    //       id: 'f3c7422f-bf67-4fae-a827-69f75fb27a01',
    //       name: 'Mrs. Ernesto Quigley',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_37.png',
    //     },
    //     value: 0.11,
    //   },
    //   {
    //     participant: {
    //       id: 'f4161e6a-dce5-4d3d-8662-bb0bf74c5e3a',
    //       name: 'Arnold Muller',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_11.png',
    //     },
    //     value: 0.09,
    //   },
    //   {
    //     participant: {
    //       id: '05e83169-9c3f-432d-886b-700ddc7c8910',
    //       name: 'Neil Feil',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_28.png',
    //     },
    //     value: 0.066,
    //   },
    //   {
    //     participant: {
    //       id: '604fb02c-5f23-407b-94bc-48fe05148693',
    //       name: 'Rosemarie Aufderhar',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_10.png',
    //     },
    //     value: 0.042,
    //   },
    //   {
    //     participant: {
    //       id: '33fa7547-d8b8-4b87-ba0b-0f78ee77cb00',
    //       name: 'Eleanor McKenzie',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_14.png',
    //     },
    //     value: 0.032,
    //   },
    //   {
    //     participant: {
    //       id: '0a1d8e2d-2316-49e2-b5de-705875d38e09',
    //       name: 'Inez Corwin',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_41.png',
    //     },
    //     value: 0.024,
    //   },
    //   {
    //     participant: {
    //       id: 'c179e04a-9201-4c70-9cc9-23060d7c5b3c',
    //       name: 'Darlene Beier',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_5.png',
    //     },
    //     value: 0.005,
    //   },
    //   {
    //     participant: {
    //       id: '600fae31-9c95-4e3c-b99c-824f40e8a7b0',
    //       name: 'Virginia Harvey',
    //       image: 'https://get-ranked.app/assets/images/account_avatars/avatar_37.png',
    //     },
    //     value: 0.001,
    //   },
    // ]);
  }
}
