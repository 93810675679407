import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Participant, PlayerDTO, playerToParticipant } from '@ranked/model';
import { ENVIRONMENT_TOKEN, EnvironmentType } from '@ranked/settings';

@Injectable({
  providedIn: 'root',
})
export class RoomClientService {
  constructor(@Inject(ENVIRONMENT_TOKEN) private environment: EnvironmentType, private httpClient: HttpClient) {}

  public getRoomParticipants(roomId: string): Observable<Participant[]> {
    return this.httpClient
      .get<PlayerDTO[]>(`${this.environment.baseUrl}/api/rooms/${roomId}/players`)
      .pipe(map((players) => players.map(playerToParticipant)));
  }
}
