import { Component } from '@angular/core';
import { Router } from '@angular/router';

const UUID_REGEX = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';

@Component({
  selector: 'ranked-join-room',
  templateUrl: './join-room.component.html',
  styleUrls: ['./join-room.component.scss'],
})
export class JoinRoomComponent {
  roomCodeParts: string[] = [];

  constructor(private router: Router) {}

  onJoinSubmit(): void {
    const roomCode = this.roomCodeParts.join('-');
    this.router.navigate(['ranking', roomCode]);
  }

  qualityOfLifeInsert(): void {
    if (this.roomCodeParts[0].toLowerCase().match(UUID_REGEX) !== null && this.roomCodeParts[0].split('-').length === 5) {
      this.roomCodeParts = this.roomCodeParts[0].split('-');
    }
  }
}
